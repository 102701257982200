import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Chromelpez from "src/components/chromelpez"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import Btf_cb_ez from "src/components/btf_cb_ez"
import CtaDisclosureCb2 from "src/components/ctadisclosurecb2"


const chromelpezData = import("./data/data.json");
const btfData = import("./data/btfData.json");

export default function prdbest() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`


    `}
    </style>
    <style type="text/css">
         </style>
    <title>Print any web page easily - Easy Print</title></Helmet>
    <section>
      <Chromelpez data={chromelpezData}>
        <CtaDisclosureCb2></CtaDisclosureCb2>
      </Chromelpez>
      <Btf_cb_ez data={btfData}>
        <CtaDisclosureCb2></CtaDisclosureCb2>
      </Btf_cb_ez>
      </section>
    </HomepageLayout>
  )
}
